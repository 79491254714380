import React from 'react'
import Select from 'react-select'

const options = {
  time: [
    { value: null, label: 'Toutes' },
    { value: 1, label: (<><i className="far fa-clock active"/><i className="far fa-clock"/><i className="far fa-clock"/> Rapide</>) },
    { value: 2, label: (<><i className="far fa-clock active"/><i className="far fa-clock active"/><i className="far fa-clock"/> Moyenne</>) },
    { value: 3, label: (<><i className="far fa-clock active"/><i className="far fa-clock active"/><i className="far fa-clock active"/> Longue</>) }
  ],
  cost: [
    { value: null, label: 'Tous' },
    { value: 1, label: (<><i className="far fa-euro-sign active"/><i className="far fa-euro-sign"/><i className="far fa-euro-sign"/> Peu coûteux</>) },
    { value: 2, label: (<><i className="far fa-euro-sign active"/><i className="far fa-euro-sign active"/><i className="far fa-euro-sign"/> Moyennement coûteux</>) },
    { value: 3, label: (<><i className="far fa-euro-sign active"/><i className="far fa-euro-sign active"/><i className="far fa-euro-sign active"/> Très coûteux</>) }
  ],
  impact: [
    { value: null, label: 'Tous' },
    { value: 1, label: (<><i className="far fa-star active"/><i className="far fa-star"/><i className="far fa-star"/> Impact faible</>) },
    { value: 2, label: (<><i className="far fa-star active"/><i className="far fa-star active"/><i className="far fa-star"/> Impact moyen</>) },
    { value: 3, label: (<><i className="far fa-star active"/><i className="far fa-star active"/><i className="far fa-star active"/> Impact fort</>) }
  ],
};

// const indicatorSeparatorStyle = {
//     alignSelf: 'stretch',
//     backgroundColor: 'red',
//     marginBottom: 8,
//     marginTop: 8,
//     width: 1,
// };

const placeholder = "Choisir..."

export const SolutionFilters = ({onUpdateFilter}) => (
  <div className="solution-filters">
      <div className="filter filter-speed">
          <label>Rapidité de mise en oeuvre</label>
          <Select
            className='react-select-container'
            classNamePrefix="react-select"
            placeholder={placeholder}
            options={options.time}
            onChange={({value}) => {
              onUpdateFilter('time', value);
            }}
            components={{ IndicatorSeparator: () => null }}
          />
      </div>
      <div className="filter filter-cost ">
          <label>Coût de mise en oeuvre</label>
          <Select
            className='react-select-container'
            classNamePrefix="react-select"
            placeholder={placeholder}
            options={options.cost}
            onChange={({value}) => {
              onUpdateFilter('cost', value);
            }}
            components={{ IndicatorSeparator: () => null }} />
      </div>
      <div className="filter filter-impact">
          <label>Impact</label>
          <Select
            className='react-select-container'
            classNamePrefix="react-select"
            placeholder={placeholder}
            options={options.impact}
            onChange={({value}) => {
              onUpdateFilter('impact', value);
            }}
            components={{ IndicatorSeparator: () => null }} />
      </div>
  </div>
)
