import React from "react";
import logo from "../assets/logo-galion-white.png";
import {BurgerMenu} from 'components/BurgerMenu';

export function Navbar() {
  return (
    <nav className="menu">
      <div className="left">
          <a href="/">galion&nbsp;<strong>impact</strong></a>
          <a href="https://thegalionproject.com/impact-solutions" className="link-tgp">
            <img className="logo-tgp" src={logo} alt="Logo The Galion Project"/>
          </a>

      </div>
      <div className="right">
          <BurgerMenu right burgerButtonClassName={ "my-class-truc-muche" } />
      </div>
    </nav>
  );
}
