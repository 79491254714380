import {HashLink} from "react-router-hash-link";
import React from "react";

export const TopLink = () => (
  <div className={"text-center toplink"}>
    <HashLink smooth to={{hash: "#top"}}>
        <i className="fal fa-arrow-to-top fa-2x" />
    </HashLink>
  </div>
);
