import {Route} from "react-router";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Scrollspy from "react-scrollspy";
import React from "react";
import cx from "classnames";
import { Sticky } from 'react-sticky';

export function Sidebar(props) {
  const solutions = props.solutions;

  return (
    <Sticky topOffset={412} disableCompensation>{({ isSticky }) => (
      <nav id="impact-nav" className={cx('inpage-nav', {'sticky': isSticky})}>
        <ul className="level1">
          <NavParent exact to="/" title={"Les solutions"}>
            <Scrollspy items={solutions.map((solution) => solution.slugThem)}
                       currentClassName="active"
                       className="level2">
              {solutions.map((solution) => (
                <li key={solution.slugThem}>
                  <HashLink smooth to={{pathname: "/", hash: "#" + solution.slugThem}} className={cx(null, {'disabled': solution.items.length === 0})}>
                    {solution.title}
                  </HashLink>
                </li>
              ))}
            </Scrollspy>
          </NavParent>
          <li><HashLink to={{pathname: "/impact/standard", hash: "#top"}}>Quelle place pour l’impact dans un monde en crise ?</HashLink></li>
          <li><HashLink to={{pathname: "/impact/investor", hash: "#top"}}>Le point de vue des investisseurs</HashLink></li>
          {/*<HashLink to={{pathname: "/impact/labels", hash: "#top"}}>Le comparatif des labels</HashLink>*/}
          <NavParent exact to={{pathname: "/impact/labels", hash: "#top"}} title={"Le comparatif des labels"}>
            <ul>
              <li><HashLink to={{pathname: "/impact/labels", hash: "#agrement-esus"}}>Agrément ESUS</HashLink></li>
              <li><HashLink to={{pathname: "/impact/labels", hash: "#bcorp"}}>BCORP</HashLink></li>
              <li><HashLink to={{pathname: "/impact/labels", hash: "#entreprise-mission"}}>Entreprise à mission</HashLink></li>
              <li><HashLink to={{pathname: "/impact/labels", hash: "#entreprise-ess"}}>Entreprise de l'ESS</HashLink></li>
            </ul>
          </NavParent>
        </ul>
      </nav>)}
    </Sticky>
  );
}
//
// class NavItem extends React.Component {
//   render() {
//     return (
//       <Route exact={this.props.exact} path={this.props.to.toString()}>
//         {
//           ({match}) =>
//             <li className={match ? 'active' : undefined}>
//               <Link to={this.props.to}>
//                 {this.props.children}
//               </Link>
//             </li>
//         }
//       </Route>
//     );
//   }
// }

class NavParent extends React.Component {
  render() {
    return (
      <Route exact={this.props.exact} path={this.props.to.toString()}>
        {
          ({match}) =>
            <li className={match ? 'active' : undefined}>
              <Link to={this.props.to}>{this.props.title}</Link>
              {this.props.children}
            </li>
        }
      </Route>
    );
  }
}
