import {Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import {ContentInvestor} from './ContentInvestor';
import {ContentLabels} from './ContentLabels';
import {ContentStandard} from './ContentStandard';

export function Content() {
  let match = useRouteMatch();

  return (
    <div className="container editorial-content">
      <div className="row justify-content-lg-right justify-content-xl-center justify-content-md-end justify-content-sm-center">
        <Switch>
          <Route path={`${match.path}/investor`}>
            <ContentInvestor/>
          </Route>
          <Route path={`${match.path}/standard`}>
            <ContentStandard/>
          </Route>
          <Route path={`${match.path}/labels`}>
            <ContentLabels/>
          </Route>
        </Switch>
      </div>
    </div>
  );
}
