import React from "react";
import logoEdr from "../assets/logo-edr-blanc.png";
import logoFT from "../assets/logo-french-tech-blanc.png";
import logoImpact from "../assets/MouvImpactFrance_blanc.png";
import {Route, Switch} from "react-router-dom";

export function Header(props) {
  return (
    <div className="row header big-header">
      <div className="page-title">
        <Switch>
          <Route exact path={'/'}>
            <Title displaySponsors={true}>
              galion <span className="bold">impact</span>
              <div className="subtitle">42 solutions pour améliorer l’impact des entreprises de la tech</div>
            </Title>
          </Route>
          <Route path={'/impact/standard'}>
            <Title displaySponsors={true}>
              Quelle place pour <span className="bold">l’impact dans un monde en crise</span> ?
            </Title>
          </Route>
          <Route path={'/impact/investor'}>
            <Title displaySponsors={true}>
              Le point de vue des <span className="bold">investisseurs</span>
            </Title>
          </Route>
          <Route path={'/impact/labels'}>
            <Title displaySponsors={true}>
              Comparatif des <span className="bold">labels</span>
              <div className="by">by &nbsp;<img width="130" className="logo-tgp" src={logoImpact} alt="Logo Mouvement Impact France"/></div>
            </Title>

          </Route>
        </Switch>
      </div>
    </div>
  );
}

function Title(props) {
  return (
    <>
      <h1 className="caps thin">{props.children}</h1>
      {props.displaySponsors &&
      <h2 className="">
        <div className="text-right">avec le soutien de :</div>
        <div className="logos-partners">
          <img width="200" className="logo-tgp" src={logoEdr} alt="Logo fondation Edmond de Rothschild"/>
          <div className="and">&</div>
          <img width="50" className="logo-tgp" src={logoFT} alt="Logo French Tech"/>
        </div>
      </h2>
      }
    </>
  );
}
