import claraGaymard from "../../assets/author/clara-gaymard.jpg";
import benoistGrossmann from "../../assets/author/benoist-grossmann.jpg";
import rezaMalekzadeh from "../../assets/author/reza-malekzadeh.jpg";
import johnny from "../../assets/author/johnny-el-hachem.jpeg";
import React from "react";

export function ContentInvestor() {
  return (
    <div className="col-lg-7 col-md-9 col-sm-12">
      <article>
        <h2 className="article-title">Plus qu’un concept, un engagement concret</h2>
        <div className="authors">
          <div className="bloc-author">
            <img src={johnny} alt="Johnny el Hachem"/>
            <div className="author">
              <div className="name">JOHNNY EL HACHEM</div>
              <div className="job-title">Président de Edmond de Rothschild Private Equity</div>
            </div>
          </div>
        </div>

        <div className="text">
          <p>
            Si, en finance, la performance reste clé - car ce qui n’est pas rentable n’est pas durable,
            elle s’accompagne désormais d’un impératif d’impact : la durabilité occupe aujourd’hui
            la même place que la rentabilité financière dans l’esprit des investisseurs.
          </p>
          <p>
            L’investissement à impact devient donc la nouvelle norme. Nous ne pouvons plus distinguer
            l’investissement responsable de l’investissement « classique » et il est de notre responsabilité
            d’intégrer l’ensemble des externalités (sociale, environnementale, gouvernementale) à nos décisions
            d’investissement. Cessons d’opposer le financier à l’impact.
          </p>
          <p>
            Cette question de la mesure d’impact est au cœur de notre philosophie et de notre façon d’aborder le Private Equity.
          </p>
          <p>
            Au-delà d’une démarche passive d’exclusion des projets qui ne respecteraient pas nos critères,
            nous accompagnons les entreprises qui optent pour une croissance plus durable.
            Cela nous permet de maximiser notre impact mais également de créer une relation de long terme
            avec les entrepreneurs que nous soutenons.
          </p>
          <p>
            Pour cela, il faut s’entourer d’équipes opérationnelles, de femmes et d’hommes de terrain qui ont développé
            une vraie expertise sur nos thématiques d’investissement. La finance doit se positionner comme un moteur
            de changement vers une économie plus durable mais cela ne pourra se faire sans la participation coordonnée
            de l’ensemble des acteurs économiques et sociaux.
          </p>
          <p>
            Enfin, nous cherchons continuellement plus de transparence et de résultats, au travers de nouveaux
            modèles de rémunération des équipes de Private Equity par exemple, qui intégreront progressivement
            des objectifs d’impact ciblés.
          </p>
          <p>
            Maison d’investissement de convictions, Edmond de Rothschild place l’impact au cœur de son approche entrepreneuriale
            et durable de l’investissement.
          </p>
        </div>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">L’impact n’est pas une niche</h2>
        <div className="authors">
          <div className="bloc-author">
            <img src={claraGaymard} alt="CLARA GAYMARD"/>
            <div className="author">
              <div className="name">CLARA GAYMARD</div>
              <div className="job-title">co-fondatrice de Raise</div>

            </div>
          </div>
        </div>

        <div className="text">
          <p>
            L’impact aujourd’hui est comparable à ce qu’était internet en 1995. Ce n’est pas une niche !
            Les entreprises qui ne veulent pas le voir passent à côté de l’énorme révolution qui est en train de se
            produire :
            aujourd’hui, l’économie ne peut plus être seulement la maximisation du profit, ce doit être une performance
            globale,
            qui prend en compte les critères sociaux et environnementaux.
          </p>
          <p>
            La pression est d’autant plus forte qu’émergent de nouveaux modèles économiques qui vont devenir
            majoritaires,
            d’une part parce qu’on n’a pas le choix, d’autre part parce qu’ils sont plus performants
            et prennent en compte la finitude des ressources. Dans ce domaine, les nouveaux entrants
            ont un avantage incontestable sur les acteurs historiques.
          </p>
          <p>
            C’est pourquoi nous sommes convaincus que, si nous n’aidons pas les entreprises
            qui ne sont pas native impact à faire leur transition, des pans entiers de l’économie vont tomber.
            Ce ne sont plus des enjeux annexes. C’est sur leur propre modèle que ces entreprises
            doivent être plus cohérentes et se fixer une trajectoire d’amélioration, avec des objectifs précis,
            et donc engageants. D’autant que le temps de la transformation est long, et l’exécution compliquée.
            La prise en compte des impacts extra-financiers va donc bien au-delà du discours commercial,
            il s’agit d’une opportunité unique pour les entreprises de se transformer en profondeur,
            de devenir plus transparentes et de rendre tangible l’ensemble de leurs impacts sur toute la chaîne de
            valeur.
          </p>
          <p>
            Côté financement, le changement viendra des investisseurs, qui vont faire évoluer les critères
            des fonds dans lesquels ils investissent. De plus en plus de fonds lient d’ores et déjà
            la performance financière à l’atteinte de KPIs d’impact. Si la rentabilité peut être moins forte
            et à plus long terme, elle reste attractive et, surtout, elle est pérenne.
            La générosité est un moteur économique prodigieux !
          </p>
        </div>
      </article>
      <hr className="sep"/>

      <article>
        <h2 className="article-title">C'est le sens de l'histoire</h2>
        <div className="authors">
          <div className="bloc-author">
            <img src={benoistGrossmann} alt="Benoist Grossmann"/>
            <div className="author">
              <div className="name">Benoist Grossmann</div>
              <div className="job-title">Managing Partner - IDInvest Partners</div>
            </div>
          </div>
        </div>
        <div className="text">
          <p>
            Aujourd’hui, si l’impact n’est pas un levier de rentabilité, ce n’est pas non plus un obstacle au profit.
            Et cela va prendre de plus en plus de place dans les choix d’investissement,
            car cela correspond à une évolution générale de la société. Nous avons intégré la politique ESG
            dans notre typologie d’investissements : il y a des secteurs d’investissement que l’on s’interdit.
          </p>
          <p>
            On constate d’ailleurs que le regard des investisseurs a changé : ils s’intéressent à la création d’emplois,
            la féminisation des équipes, ou la consommation d’énergie d’un projet… L’impact, c’est le sens de l’Histoire
            !
          </p>
        </div>
      </article>

      <hr className="sep"/>

      <article>
        <h2 className="article-title">On ne peut plus opposer impact et rentabilité</h2>
        <div className="authors">
          <div className="bloc-author">
            <img src={rezaMalekzadeh} alt="Reza Malekzadeh"/>
            <div className="author">
              <div className="name">Reza Malekzadeh</div>
              <div className="job-title">General Partner – Partech Ventures</div>
            </div>
          </div>
        </div>
        <div className="text">
          <p>
            L’impact n’est plus du tout une notion caritative : on ne peut plus opposer impact et rentabilité.
            En terme de temporalité de la profitabilité, certains projets demandent plus de temps mais la notion
            d’impact n’augmente pas nécessairement le cycle de l’investissement.
          </p>
          <p>
            La question de l’impact a pris beaucoup plus d’ampleur ces dernières années. Parce que c’est aujourd’hui
            un moteur pour les entrepreneurs, qui ont de plus en plus de projets autour de ça ; parce que les nouvelles
            générations ont aussi envie d’impact sociétal, donc c’est un puissant facteur d’attractivité des meilleurs
            talents ; et parce que cela correspond aux évolutions des attentes du marché. Cela peut aussi être un levier
            marketing, mais tous les fondateurs n’ont pas envie de l’actionner.
          </p>
          <p>
            Du coup, même si nous restons généralistes, la part des entreprises à impact augmente naturellement dans
            notre portefeuille. C’est conforme à la réalité des projets qui se montent. La typologie des entrepreneurs
            a évolué aussi : il y a 20 ans, il n’y avait pas autant d’entreprises créées par des femmes ou des personnes
            issues de la diversité…
          </p>
          <p>
            Sur les KPIs d’impact, en tant qu’investisseurs, nous faisons aussi certains constats.
            Par exemple qu’une équipe diversifiée est bien plus riche, plus innovante dans sa façon d’aborder les
            problèmes.
          </p>
          <p>
            Tout cela modifie aussi nos organisations : nous essayons par exemple d’avoir une équipe plus représentative
            de l’écosystème car, en tant qu’investisseurs, nous devons être plus attractifs pour les minorités et
            montrer que l’on peut traiter tous les dossiers, sans discrimination.
          </p>
        </div>
      </article>

    </div>
  );
}
