import React from "react";

export function ContentStandard() {
  return (
    <div className="col-lg-7 col-md-9 col-sm-12">
      <article className="article-intro">
        <h2 className="article-title">L’impact a-t-il une place dans un monde en crise ?</h2>
        <p>
          La première version des Galion Impact Solutions en 2019 partait d’un constat : la montée en puissance,
          dans le débat public, de l’impact social et environnemental des organisations annonçait une révolution
          comparable à celle du digital dans les années 90. Hier, signal faible appréhendé par une poignée
          d’experts, l’impact<a href="#note1"><sup>[1]</sup></a>, comme le digital il y a 30 ans, était en passe de redéfinir notre modèle de société et de
          conditionner la pérennité des entreprises.
        </p>
        <p>
          Ce bouleversement s’inscrivait dans un contexte de profonde remise en question d’une vision de la
          croissance qui ne serait que financière, au détriment de la performance sociale et environnementale. Il
          incitait les entreprises à interroger leurs modèles et à assumer un rôle sociétal élargi, bien au-delà de leurs
          publics naturels. Animé par la conviction que l’impact est la condition d’une croissance durable des
          entreprises, The Galion Project souhaitait, en publiant 40 solutions expérimentées et validées par ses
          membres, aider les entrepreneurs à passer à l’acte et à initier une démarche d’impact.
        </p>
        <p>
          Aujourd’hui, face à une crise qui se prolonge, il est trop tôt pour savoir ce que sera le fameux « monde de
          demain » . Mais on peut d’ores et déjà s’interroger sur la place qu’y tiendra la notion d’impact : la crise de la
          COVID l’a-t-elle reléguée au second plan face à l’urgence économique et sociale ? Faudra-t-il arbitrer entre
          capital humain et responsabilité environnementale ? Et quels sont les enjeux propres à la Tech ?
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">La résilience de la Tech face à la crise</h2>
        <p>
          C’est devenu une évidence : à quelques exceptions près, la Tech est la grande gagnante de la crise. Le
          confinement a provoqué une incroyable accélération de la digitalisation des usages, dans presque tous les
          secteurs : en quelques mois des populations jusqu’alors éloignées du digital – et notamment les seniors
          –ont adopté les Zooms, Teams et autres Livestorm, tout comme le e-commerce et les consultations
          médicales en visio … La Tech a permis le travail à distance, donné accès à l’éducation, à la santé, aux
          achats, à la culture, elle a préservé le lien social, permis de formidables actions de solidarité comme
          “Protège ton soignant”, secondé les services publics… Bref, en palliant la distanciation physique, la Tech
          s’est révélée indispensable.
        </p>
        <p>
          Les marchés ne s’y sont pas trompés. Le Nasdaq, indice fortement lié aux valeurs technologiques, a connu
          une hausse de 43% en 2020, enchaînant même les records historiques en fin d’année. En France, les
          levées de fonds ont battu un nouveau record : les start-up de la French Tech ont levé 5,4 milliards d’euros
          en 2020 vs 5,03 en 2019, avec une augmentation de 88% des tours à plus de 50M€, et la valeur moyenne
          des levées est passée de 6,9M€ en 2019 à 8,7M€ en 2020 (baromètre EY).
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">La montée en puissance des acteurs de l’impact</h2>
        <p>
          Les entreprises à impact s’alignent désormais sur les mêmes standards que celles dont ce n’est pas le
          cœur de métier : on compte en 2020 parmi les 5 plus grosses levées de fonds en France 3 entreprises à
          impact&nbsp;: Ecovadis (182M€), Backmarket (110M) et Ynsect (224M)… <em>“Le marché a gagné en maturité, tant
          au niveau de la qualité et de l&#39;ambition des entrepreneurs à impact que sur la culture des fonds spécialisés,
          et les entreprises Tech For Good font dorénavant des levées tout aussi importantes, elles ont de tout aussi
          grands objectifs : nous n’avons plus à rougir »</em> affirme Jean Moreau, co-fondateur de Phénix, qui a levé
          plus de 25M€ en trois tours, pour digitaliser l’aide alimentaire et permettre aux consommateurs de lutter
          contre le gaspillage via une application mobile.
        </p>
        <p>
          Cette montée en puissance des entreprises à impact de la Tech s’inscrit dans une tendance de fond, vers
          laquelle convergent toutes les parties prenantes de l’entreprise.
        </p>
        <p>
          <strong>Les clients</strong> sont de plus en plus nombreux à être séduits par la consommation responsable. <em>« C’est une
          vraie tendance de fond »</em> analyse Thierry Petit (Showroomprivé.com). Avec la crise et les contraintes
          économiques de plus en plus fortes, celle-ci est en passe de devenir un standard : en témoigne l’essor du
          marché de la seconde main et des invendus, de Vinted à Showroomprivé, en passant par Backmarket.
        </p>
        <p>
          En BtoB, <strong>les grands groupes</strong> sont sommés par les actionnaires d’introduire des critères de responsabilité&nbsp;:
          leurs prestataires doivent désormais être durables pour être désirables. Pour Guillaume Lecointre, de
          Lecointre Paris, combiner dans son offre de restauration aux entreprises la digitalisation du parcours client
          et une alimentation locale et responsable lui permet d’assurer la restauration aussi bien de sociétés du
          CAC40 que de start-up de la Tech engagées sur les sujets à fort impact environnemental.
        </p>
        <p>
          <strong>Les jeunes diplômés</strong>, en France et aux US notamment, sont de plus en plus nombreux à choisir leur
          employeur selon des critères de respect de l’environnement&nbsp;: fin 2018, un collectif d’étudiants de grandes
          écoles a signé un « Manifeste pour un réveil écologique », qui réunit aujourd’hui plus de 30 000
          signataires. Amira Haberah (Zoov) le constate : <em>« il y a eu un vrai tournant : aujourd’hui, la qualité de vie au
          travail et la responsabilité sociale et environnementale de l’entreprise sont de vrais leviers pour le
          recrutement d’un talent. »</em> Un incontournable donc, dans un contexte de guerre des talents.
        </p>
        <p>
          <strong>Côté investisseurs</strong>, le marché de l’impact investing, dont l’objectif est d’allier rentabilité et impact positif,
          se monte en 2020 à 715 milliards de dollars selon le GIIN (Global Impact Investing Network). Larry Fink,
          CEO de BlackRock, écrivait en janvier 2020 dans sa lettre annuelle à ses clients : <em>« Nous estimons que
          l’investissement durable représente désormais le meilleur gage de robustesse pour les portefeuilles des
          clients. »</em> Le plus gros gestionnaire d’actifs au monde a annoncé en avril le lancement d’un fonds à impact
          pour investir dans les entreprises contribuant aux objectifs de développement durable de l’ONU.
        </p>
        <p>
          Enfin <strong>les entrepreneurs et entrepreneures</strong>, que ce soit par recherche de sens ou volonté d’explorer ce
          nouveau champ des possibles, sont de plus en plus nombreux à souhaiter donner du sens à leur action,
          comme Charles-Edouard Vincent, qui après Polytechnique, Stanford et quelques années dans la Silicon
          Valley, a changé de carrière pour diriger pendant 10 ans Emmaüs. La création de «&nbsp;Lulu dans ma rue&nbsp;»,
          explique-t-il, <em>«&nbsp;c’est la synthèse de mes deux précédentes vies&nbsp;:&nbsp;je voulais mettre le numérique au service
          de l’impact social et il y avait un chaînon manquant dans l’insertion professionnelle. «&nbsp;Lulu dans ma rue&nbsp;»
          réinvente les petits boulots, qui sont un vecteur essentiel d’insertion et de dignité des personnes.&nbsp;»</em>
        </p>
        <p>
          En croisant l’apport des nouvelles technologies et une demande sociétale de plus en plus forte, les start-up
          à impact ont le vent en poupe. <em>« Avoir une mission et un impact forts constitue de plus en plus un atout
          pour la marque employeur, et une source inépuisable de motivation pour l&#39;équipe au quotidien »</em> confirme
          Jean Moreau.
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">Le sens de l’Histoire</h2>
        <p>
          Mais l’impact dépasse largement le champ d’action des entreprises dont c’est le cœur de métier&nbsp;: c’est
          désormais un sujet pour toutes les entreprises, quels que soient leur taille, leur secteur, leur business
          model. <em>«&nbsp;Toute entreprise fait partie d’un écosystème, et a de facto une responsabilité,&nbsp;»</em> analyse Jean-
          Baptiste Rudelle (The Galion Project). <em>«&nbsp;Elle crée des externalités et doit faire en sorte qu’elles soient le
          plus positives possible. C’est important pour donner de la crédibilité vis-à-vis des clients, des actionnaires,
          des employés.&nbsp;»</em> Au-delà des convictions, intégrer l’impact dans ses indicateurs de performance relève
          désormais du bon sens économique, voire d’un impératif dans un système en pleine évolution. <em>«&nbsp;Des sujets
          comme l’environnement, le partage de la valeur<a href="#note2"><sup>[2]</sup></a> ou la meilleure représentation des salariés dans
          l’entreprise, c’est le sens de l’Histoire, tout simplement,&nbsp;»</em> poursuit Jean-Baptiste.
        </p>
        <p>
          D’autant que l’évolution du cadre juridique, des 17 objectifs de développement durable de l’ONU à la Loi
          Pacte, qui inscrit dans le code civil et le code du commerce la prise en considération des enjeux sociaux et
          environnementaux par les entreprises, redéfinit progressivement le cadre de leur action.
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">L’impact, un luxe en temps de crise ?</h2>
        <p>
          Plusieurs études le confirment : <strong>la responsabilité de l’entreprise est maintenant un indicateur de
          performance essentiel, voire un levier de croissance incontournable</strong>. Une méta-étude d’Oxford
          University intitulée « from the stockholder to the stakeholder », réalisée en 2015 et basée sur l’analyse
          approfondie de plus de 200 sources différentes, a ainsi établi la corrélation entre les bonnes pratiques
          commerciales en matière de durabilité et la rentabilité économique.
        </p>
        <p>
          Améliorer son impact environnemental et social implique nécessairement de procéder à des ajustements,
          de mettre d’autres process en place, parfois plus longs ou plus coûteux. Un luxe en temps de crise&nbsp;? Non,
          <em>« un investissement&nbsp;»</em> rétorque Jean-Baptiste Rudelle&nbsp;: <em>«&nbsp;la définition même d’investir, c’est de privilégier le
          temps long contre le temps court : ça implique de savoir anticiper le bénéfice à terme. Pour que l’impact
          fonctionne vraiment, c’est beaucoup plus efficace de le gérer comme un levier de performance, plutôt que
          comme une contrainte ou une obligation.&nbsp;»</em>
        </p>
        <p>
          Pour Fred Plais (Platform.sh), <em>«&nbsp;c’est un élément du succès d’une boîte de projeter une mission inspirante,
          utile et contributrice à une cause qui la dépasse. La pandémie a accéléré ce mouvement&nbsp;: le monde est
          différent&nbsp;»</em>. Et la crise, loin de ralentir cette évolution, l’aurait au contraire accélérée&nbsp;: <em>« elle est un
          catalyseur de changement,”</em> note Jean Moreau, <em>“ beaucoup de dirigeant.e.s ont pris la mesure des
          changements sociétaux à l&#39;occasion de cette crise, et bon nombre d’entreprises en ont profité pour
          accélérer sur des transformations de fond en matière d’impact » .</em>
        </p>
        <p>
          Il en va de même du côté des investisseurs, note Eva Sadoun (Lita.co) : <em>“Aujourd’hui la situation sanitaire a
          révélé la nécessité de développer des technologies à impact en vue des prochaines crises sociales et
          climatiques. Les investisseurs privés s’en soucient et de plus en plus de fonds repositionnent leur stratégie
          d’investissement sur l’impact, mais cela doit se généraliser davantage et répondre aux objectifs de développement durable.”</em>
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">Les conditions de l’impact</h2>
        <p>
          Mais le sujet est hyper-complexe, et il est souvent difficile d’identifier clairement les externalités négatives
          d’une action apparemment responsable. Rendre sa chaîne d’approvisionnement plus vertueuse sur le plan
          environnemental peut entraîner une suppression d’emplois et donc un impact social négatif. Même avec la
          meilleure volonté du monde, il n’est pas évident d’avoir une traçabilité parfaite. Le mieux est l’ennemi du
          bien&nbsp;: il faut des petits pas pour engager les équipes et ne pas essayer d’être parfait.
        </p>
        <p>
          Un préalable : la sincérité de la démarche. <em>“Quelle que soit l’action, petite ou grande, la sincérité de la
          démarche est cruciale à un moment où les risques de récupération sont importants. Si c’est de la pure
          comm et qu’il n’y a rien derrière, le retour de bâton peut être violent,”</em> rappelle Christophe Poupinel (Share
          it).
        </p>
        <p>
          Thierry Petit&nbsp;(Showroomprivé) préconise <em>« d’appliquer à une démarche d’impact les mêmes exigences de
          performance que pour n’importe quel projet entrepreneurial&nbsp;: il faut des KPIs, des objectifs et un modèle
          économique ! Autrement ça n’est pas pérenne, et ça n’a pas de sens. Les consommateurs recherchent de
          l’impact, mais ils veulent aussi le juste prix et le volume d’offre&nbsp;: c’est complémentaire.&nbsp;»</em>
        </p>
        <p>
          C’est l’intérêt des labels, pointe Guillaume Gibault&nbsp;(le Slip Français) : sortir du discours pour avoir une
          démarche rigoureuse et formalisée, monitorer précisément les actions prises et leur résultat. <em>«Ca permet
          un alignement de la stratégie et de l’opérationnel, avec des KPIs dédiés et un pilotage précis, au-delà des
          déclarations,&nbsp;»</em> selon Guillaume. Après avoir obtenu le label Lucie, le Slip Français est en cours de
          certification par le label BCorp, dont la lisibilité, la visibilité à l’international et la <em>«&nbsp;triple bottom line&nbsp;: people,
          planet, profit&nbsp;»</em> séduisent un nombre croissant d’entrepreneurs.
        </p>
        <p>
          Le champ d’action est si vaste que chacun peut doser son engagement en fonction de son secteur, son
          expertise et sa sensibilité. Pour Charles-Edouard Vincent, <em>«&nbsp;il faut choisir sur quoi on a envie de peser&nbsp;à
          partir de ce qui tient réellement à cœur, parce que l’enjeu est de s’engager sur le long terme. »</em> Et analyser
          les enjeux propres à son secteur et son entreprise : Fred Bardeau (Simplon.co) préconise de <em>«&nbsp;géolocaliser
          la RSE et d’avoir des actions sur son propre territoire »</em>. C’est ainsi que Simplon a accompagné la fondation
          Showroomprivé.com pour monter une école du numérique et répondre aux problèmes de chômage sur son
          territoire d’implantation en utilisant son expertise, le e-commerce.
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">L’environnement, intégré aux pratiques de l’entreprise&nbsp;?</h2>
        <p>
          La question environnementale semble s’être intégrée durablement dans le champ de responsabilité de
          l’entreprise, portée par une prise de conscience de plus en plus forte des enjeux liés au réchauffement
          climatique et par des débats de fond, tels que l’inscription de l’environnement dans la Constitution, dont le
          projet de loi a été présenté le 20 janvier.
        </p>
        <p>
          Beaucoup d’actions sont aujourd’hui entrées dans les pratiques courantes de l’entreprise, et perçues
          comme des bénéfices autant pour l’environnement que pour une saine gestion financière. Que ce soit les
          écogestes, pour faire baisser la facture énergétique et engager les équipes en instaurant une culture de la
          responsabilité&nbsp;; ou la gestion responsable des parcs d’équipements mobiles ou informatiques (le numérique
          représente presque 4% des gaz à effet de serre de la planète)&nbsp;: opter pour le reconditionné ou la double
          carte SIM relève autant des convictions que de la rationalisation des coûts.
        </p>
        <p>
          La crise liée au COVID n’a fait que renforcer cette tendance lourde, produisant un effet de cliquet en faveur
          de comportements plus respectueux de l’environnement&nbsp;: tout le monde, individus comme entreprises, a
          interrogé ses pratiques et un retour en arrière est peu probable.
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">Le capital humain au coeur de la crise</h2>
        <p>
          Mais c’est la question sociale qui occupe le devant de la scène depuis le début de la crise sanitaire.
          L’impact était jusqu’à présent principalement perçu comme une problématique d’externalités : les
          conséquences des actions de l’entreprise sur son environnement extérieur. Le confinement a brutalement
          recentré la notion sur l’interne&nbsp;: le capital humain. <em>«&nbsp;Le monde est différent&nbsp;: on vit un énorme stress
          psychologique et économique. Cela a fait monter en flèche les valeurs d’empathie, de solidarité,&nbsp;»</em> analyse
          Fred Plais.
        </p>
        <p>
          Et effectivement, les équipes seront déterminantes pour la résilience de l’entreprise, face à une crise d’une
          ampleur sans précédent depuis la 2e guerre mondiale… <em>«&nbsp;Nos équipes ont eu la vie dure. Or sans ses
          équipes une entreprise n’est rien&nbsp;: sa pérennité et sa croissance reposent sur son capital humain.&nbsp;Cette
          crise rappelle le rôle central qu’elles jouent, et à quel point elles ont été sollicitées, »</em> souligne Frédéric
          Bardeau (Simplon).
        </p>
        <p>
          Dans une France marquée par une culture du présentéisme, l’instauration soudaine du télétravail, sans
          préparation ni organisation préalable, alliée à un contexte profondément anxiogène, a déstabilisé les
          entreprises et fragilisé les individus privés brutalement de la dimension sociale du travail.
        </p>
        <p>
          La fragilisation de certains collaborateurs par cet isolement brutal et subi a amené les entreprises à
          reconsidérer ce qui relevait de leur champ de responsabilité ou pas. <em>«&nbsp;Ce n’est pas dans la culture des
          entreprises de prendre soin de la santé mentale de ses équipes&nbsp;»</em> note Céline Lazorthes
          (Leetchi/Mangopay), qui a mis en place un service de soutien psychologique anonyme pour ses équipes.
        </p>
        <p>
          <em>«&nbsp;Prendre soin des salariés est devenu encore plus qu’avant un enjeu majeur avec la crise&nbsp;»</em>, poursuit-elle.
          Et la perspective d’une crise qui s’installe dans la durée pourrait amener l’entreprise à investir durablement
          une sphère considérée jusque-là comme privée, afin de répondre à ces nouvelles problématiques RH.
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">Maintenir le sentiment d’appartenance</h2>
        <p>
          Dans ces moments de crise, et encore plus dans une entreprise distribuée, la culture et les valeurs de
          l’entreprise sont cruciales pour pallier le délitement du collectif. &nbsp;La solidarité et la bienveillance,
          considérées avec scepticisme lorsque c’était une entreprise qui les revendiquait, jouent aujourd’hui un rôle
          unificateur pour des collaborateurs qui ne se rencontrent plus<a href="#note3"><sup>[3]</sup></a>. <em>« Avec le travail à distance, les soft skills, la
          capacité d’écoute, de collaboration et d’empathie, prennent encore plus d’importance. Il faut redéfinir les
          bases de la confiance : ça implique de surcommuniquer et de formaliser les choses, »</em> explique Julie de
          Pimodan (Fluicity). Une charte de télétravail, définie de manière collaborative, lui a permis de prévenir les
          abus tout en emportant l’adhésion des équipes. <em>« Prendre le pouls du bien-être des équipes, instaurer une
          culture du dialogue et de la transparence… ce sont autant de pratiques qui doivent devenir des réflexes, »</em>
          renchérit Alix de Sagazan (AB Tasty)
        </p>
        <p>
          Avec une organisation du travail bouleversée par le remote, <em>« il faut à tout prix maintenir le lien social et le
          sentiment d’appartenance : ce sera un énorme enjeu dans l’année à venir, car même la personne la plus
          introvertie a besoin du collectif »</em>, prévient Victor Carreau (Comet Meetings), qui a développé avec des experts de
          gestion de crise une méthode de réunion pour réengager les équipes.
        </p>
        <p>
          Cette nouvelle organisation pose aussi de nouveaux problèmes : la gestion de la parentalité devient ainsi
          un vrai sujet d’organisation du travail dans un cadre de <em>home-office</em>, et révèle au passage les fortes
          inégalités hommes/femmes, celles-ci assumant une majeure partie des tâches liées aux enfants<a href="#note4"><sup>[4]</sup></a>.
        </p>
        <p>
          Nombre de fondateurs et fondatrices assument un rôle de précurseur en matière d’impact social en interne.
          Ainsi le Parental Act pour l’allongement du congé second parent, initié par Céline Lazorthes, et signé par
          391 entrepreneurs, a devancé la loi qui portera le congé de 14 à 28 jours à partir de juillet 2021. Jean-
          Charles Samuelian, qui a instauré un congé de 5 semaines chez Alan, considère que <em>“c&#39;est à la fois une
          question de valeurs, d’équité et de management. Le bénéfice pour l'entreprise est bien supérieur au coût :
          ça fidélise et équilibre les équipes, qui sont heureuses et motivées à leur retour.
          On est très heureux que ça rentre dans le cadre légal. Ça nous pousse à toujours
          plus innover en matière de culture d'entreprise.&nbsp;»</em>
        </p>
      </article>
      <hr className="sep" />
      <article>
        <h2 className="article-title">Diversité et inclusion</h2>
        <p>
          Enfin, impossible de parler d’impact social sans évoquer les enjeux propres à la Tech. Et notamment la
          question de la diversité : qu’il s’agisse du sexe, de l’origine sociale et culturelle, de l’âge ou de la formation,
          l’écosystème est montré du doigt pour son homogénéité.
        </p>
        <p>
          C’est un fait&nbsp;: les femmes, par exemple, sont sous-représentées dans l’écosystème, que ce soit en tant que
          salariées (27,4%) ou fondatrices (10%), et une femme sur deux quitte la Tech au bout de 8 ans, rappelle
          Caroline Ramade, qui a fondé 50 in Tech pour féminiser les entreprises du numérique.<a href="#note5"><sup>[5]</sup></a>
        </p>
        <p>
          Les raisons de cette homogénéité, culturelles et systémiques, dépassent largement la seule responsabilité
          des entreprises&nbsp;: schémas sociétaux ancrés, inégalités territoriales, uniformité sociale des grandes écoles
          qui n’a pas évolué en 10 ans en dépit des politiques publiques, selon une étude de l’Institut des Politiques
          Publiques (janvier 2021)…
        </p>
        <p>
          Pour Guillaume Gibault, le scandale qui a frappé le Slip Français début 2020, à la suite de la diffusion
          d’images d’une soirée privée entre salariés, a été l’occasion d’une prise de conscience brutale du manque
          de diversité de ses équipes comme dans l’écosystème Startup au global&nbsp;: l’épisode l’a engagé dans une politique volontariste avec des acteurs
          historiques de la diversité, Diversidays et Mozaïk RH, et l’appui de Mixity, dans le cadre de l’initiative
          #TechYourPlace. <em>«&nbsp;Cette crise nous a fait avancer,&nbsp;»</em> dit Guillaume, <em>«&nbsp;il faut prendre conscience du
          problème, et surtout mener des actions, avec des KPIs et un plan d’action pour être efficace.&nbsp;»</em> Par volonté
          de contribuer à rendre l’écosystème start-up plus conforme à la réalité de la société, et par conviction que
          la diversité est une forme d’intelligence collective qui favorise la créativité, l’innovation, et donc la
          performance. Une étude de McKinsey «&nbsp;Diversity wins&nbsp;» (2019) a ainsi démontré que les entreprises dont
          les équipes dirigeantes incluent des personnes de la diversité sont 36% plus susceptibles d&#39;avoir une
          rentabilité supérieure à la moyenne. Et 25% pour celles qui intègrent des femmes. Pourtant, seules 13,8%
          des start-up ont formalisé une politique en matière de diversité<a href="#note6"><sup>[6]</sup></a> ...
        </p>
        <p>
          La diversité est une question de performance, mais aussi d’alignement entre le discours et
          les actions. <em>«&nbsp;Une entreprise qui prône les valeurs du développement durable, de la RSE ne peut pas avoir des
          pratiques discriminantes. Il faut une cohérence&nbsp;»</em>, souligne Fred Bardeau.
        </p>
        <p>
          C’est aussi - et surtout - un enjeu sociétal majeur&nbsp;: à l’heure où la Tech définit le monde de demain, il est
          essentiel que chacun puisse contribuer à la conception de ses usages, biens et services pour qu’ils
          correspondent aux besoins de l’ensemble de la population.
        </p>
        <br />
        <p>
          L’impact, à la fois opportunité commerciale et proposition de valeur dans un monde ébranlé par une crise
          économique, sociale et environnementale sans précédent, est donc plus que jamais un champ à investir
          pour les entreprises, dont le rôle sociétal se trouve renforcé . La Tech, de plus en plus prépondérante, a un
          rôle majeur à jouer et, en Europe, une véritable opportunité&nbsp;: incarner un modèle respectueux des individus
          et des ressources, en rupture avec les modèles chinois et américains qui ont plus que jamais montré leurs
          limites en 2020.
        </p>
        <p>Laurence Lucas</p>
      </article>
      <hr className="sep" />
      <article className="notes">
        <p>Notes :</p>
        <ul>
          <li id="note1">
            [1] Par impact, on entend les effets (sur les parties prenantes externes, internes, directes et indirectes ou sur la société
            en général) des pratiques mises en place par les entreprises pour respecter les principes du développement durable
            dans ses dimensions économique, sociale et environnementale.
          </li>
          <li id="note2">
            [2] Voir <a href="https://thegalionproject.com/kit-bspce">le Kit BSPCE</a>
          </li>
          <li id="note3">
            [3] Voir à ce sujet <a href="https://thegalionproject.com/values-process">le Galion Values Process</a>,
            pour définir les valeurs de son entreprise
          </li>
          <li id="note4">
            [4] Note Insee 19 juin 2020 sur les conditions de vie pendant le confinement
          </li>
          <li id="note5">
            [5] Voir <a href="https://thegalionproject.com/gender-agreement">le Galion Gender Agreement</a>,
            pour aider les entreprises de la Tech à se féminiser
          </li>
          <li id="note6">
            [6] Etude “Diversité et inclusion en start-ups” du collectif First Talent (septembre 2020)
          </li>
        </ul>
      </article>



    </div>
  );
}
