import React from "react";

export function ContentLabels() {
  return (
    <div className="col-lg-7 col-md-9 col-sm-12">
      <article>
        <h2 className="article-title">Labels, agréments, déclaration statutaire : quel usage ?</h2>
        <p>
          Il existe différents labels, agréments et déclarations statutaires qui permettent de faire reconnaître
          l’engagement social/environnemental de son entreprise voire de bénéficier d’avantages spécifiques
          du fait d’une reconnaissance d’utilité sociale. Pour comprendre de façon synthétique le rôle
          des principales alternatives qui existent et faciliter leur compréhension et leur utilisation éventuelle,
          nous en proposons une synthèse ci-dessous.
        </p>
      </article>
      <hr className="sep"/>
      <article id={"agrement-esus"}>
        <h2 className="article-title">Agrément ESUS</h2>
        <h3>De quoi s'agit-il ?</h3>
        <p>
          Depuis le 1er juillet 2015, les entreprises de l'ESS peuvent demander un agrément "entreprises solidaire
          d'utilité sociale " auprès de la préfecture de leur siège social.
        </p>
        <p>
          Agrément permettant d’accéder à certains dispositifs de soutien et de financement spécifique aux entreprises
          à impact social. C’est aujourd’hui le seul argument étatique reconnaissant les entreprises à vocation
          d’intéret général selon trois critères :
        </p>
        <ul>
          <li>L’utilité sociale</li>
          <li>Une répartition équitable de la valeure</li>
          <li>Une gouvernance démocratique</li>
        </ul>
        <h3>Avantages</h3>
        <p>
          Il permet aux structures éligibles d'obtenir des fonds issus de l'épargne salariale solidaire , de bénéficier
          de dispositifs de financement spécifiques de Bpifrance, d’avoir une facilitation de financement par les fonds
          d’impact investing devant financer un quota de ESUS pour être reconnu d’utilité sociale.
        </p>
        <p>
          Il permet aussi de bénéficier du dispositif local d’accompagnement (DLA) et de quelques avantages fiscaux pour
          les éventuels investisseurs (IR MADELIN – volet Solidaire)
        </p>
        <p>
          Bon statut hybride permettant à une structure marchande sous format juridique relativement classique
          (type SAS) de rentrer dans le champ de l’Economie Sociale et Solidaire / de l’impact.
        </p>
        <h3>Comment l'obtenir ?</h3>
        <ul>
          <li>Pré-requis : être reconnu entreprise de l’ESS</li>
          <li>Objectif d’utilité publique ayant un impact significatif sur les résultats.</li>
          <li>Échelle de rémunération limitée</li>
          <li>Ou activité spécifique : IAE, Handicap, CHRS, etc</li>
        </ul>
        <p>
          Pour être éligibles à l’agrément « ESUS », les entreprises de l’économie sociale et solidaire doivent remplir
          les conditions suivantes:
        </p>
        <ul>
          <li>
            poursuivre une utilité sociale à titre d’objectif principal (soit en direction des publics vulnérables,
            soit en faveur du maintien ou de la recréation de solidarités territoriales), cet objectif devant figurer
            dans les statuts de l’entreprise ;
          </li>
          <li>
            prouver que la recherche d’utilité sociale a un impact soit sur le compte de résultat, soit sur la
            rentabilité de l’entreprise ;
          </li>
          <li>
            avoir une politique de rémunération respectant 2 conditions : la moyenne des sommes versées, y compris les
            primes, aux 5 salariés ou dirigeants les mieux payés ne doit pas excéder un plafond annuel fixé à 7 fois le
            smic et la rémunération versée au salarié le mieux payé ne doit pas excéder un plafond annuel fixé à 10
            fois le smic ;
          </li>
          <li>
            les titres de capital de l’entreprise ne doivent pas être négociés sur un marché financier.
          </li>
        </ul>
        <p>
          Pour obtenir le label ESUS, il faut remplir un dossier de demande d’agrément ESUS. Les dossiers sont
          disponibles en ligne sur le site des Direccte (Direction Régionale des Entreprises, de la Concurrence,
          de la Consommation, du Travail et de l'Emploi).
        </p>
        <h3>Inconvénients ?</h3>
        <p>
          Agrément limité à 2 ans pour une entreprise de – de 3 ans, et à 5 ans pour entreprise de plus de 3 ans.
        </p>
        <p>
          Frein potentiel à l’investissement de fonds classiques limitation des écarts de salaires et impossibilité de
          l’IPO aujourd’hui (lobbying à démarrer).
        </p>
        <h3>Liste des entreprises agrées ESUS (en cours) :</h3>
        <p>
          <a href="http://www.esspace.fr/liste-entreprises-esus.html">http://www.esspace.fr/liste-entreprises-esus.html</a>
        </p>
        <hr className="minisep" />
        <div className="notes">
          <h4>Textes de référence :</h4>
          <ul>
            <li>
              CNCRES (2015) : Agrément ESUS : le nouvel agrément “entreprise solidaire” [en téléchargement]
              (<a href="https://www.ess-france.org/">www.cncres.org</a>)
            </li>
            <li>
              Loi ESS du 31 Juillet 2014
            </li>
          </ul>
        </div>

      </article>
      <hr className="sep"/>
      <article id={"bcorp"}>
        <h2 className="article-title">BCORP</h2>
        <h3>De quoi s'agit-il ?</h3>
        <p>
          Label privé.
        </p>
        <h3>Avantages</h3>
        <ul>
          <li>Valorisation de l’engagement d’une entreprise (mais ne nécessitant pas forcément d’avoir un coeur d’activité à impact)</li>
          <li>S’identifier comme un leader RSE de son secteur</li>
          <li>Bourses académiques</li>
          <li>Reconnu à l’international</li>
          <li>Accès à une communauté d’entrepreneurs</li>
        </ul>
        <h3>Comment l'obtenir ?</h3>
        <p>
          Atteinte d’un niveau minimum d’engagement pour attribution du label et engagement à améliorer son impact social.
        </p>
        <p>
          Modification de l’objet social des statuts pour que l’entreprise s’engage à avoir un impact sociétal et
          environnemental positif et significatif, et modification des dispositions de gouvernance pour que les
          dirigeants considèrent ces enjeux dans leur prise de décision envers toutes les parties prenantes (employés,
          filiales, fournisseurs, clients, communauté d’intérêts).
        </p>

        <h3>Inconvénients</h3>
        <ul>
          <li>Label privé</li>
          <li>Label réévalué tous les 2 ans</li>
        </ul>
        <p>
          2 600 sociétés labellisée dans le monde (encore limité et souvent appliqué sur des entreprises déjà bien implantées dans leur marché).
        </p>
      </article>

      <hr className="sep"/>

      <article id={"entreprise-mission"}>
        <h2 className="article-title">Entreprise à mission</h2>
        <h3>De quoi s'agit-il ?</h3>
        <p>
          Déclaration statutaire de la mission de l’entreprise.
        </p>
        <p>
          Une entreprise à mission est une entreprise ou société commerciale qui intègre dans ses statuts un objectif
          d’ordre social ou environnemental. Cela signifie que l’entreprise se donne officiellement une mission qui vise
          à l’intérêt général (par exemple : avoir un impact environnemental réduit voire positif ou contribuer à
          l’activité économique locale).
        </p>
        <h3>Avantages / Valeurs ajoutées</h3>
        <ul>
          <li>Engagement de toutes les parties prenantes autour d’une mission commune</li>
          <li>Pas d’autres contraintes imposées</li>
        </ul>
        <h3>Comment l'obtenir ?</h3>
        <p>
          Cinq conditions :
        </p>
        <ul>
          <li>Produire un impact social positif;</li>
          <li>Formaliser une mission spécifique;</li>
          <li>Cohérence entre le modèle économique et la mission de l’entreprise;</li>
          <li>Evaluation de la mission;</li>
          <li>Partage équitable de la valeur créée.</li>
        </ul>
        <p>
          Des engagements sur :
        </p>
        <ul>
          <li>
            La définition d’un Objet Social Étendu (OSE), éventuellement en co-construction avec les parties prenantes.
            C’est la définition de la « mission » d’intérêt public de l’entreprise.
          </li>
        </ul>
        <p>
          Mécanisme d’engagement officiel : inscription dans les statuts légaux de l’entreprise;
        </p>
        <p>
          Mécanisme de contrôle : procédures permettant de vérifier la conformité des activités de l’entreprise par
          rapport à l’objectif et à la mission fixés (rapport).
        </p>
      </article>

      <hr className="sep"/>

      <article id={"entreprise-ess"}>
        <h2 className="article-title">Entreprise de l'ESS</h2>
        <h3>De quoi s'agit-il ?</h3>
        <p>
          Reconnaissance législative.
        </p>
        <h3>Avantages</h3>
        <ul>
          <li>Éligibilité aux financements solidaires</li>
          <li>Facilité d’accès aux financements publics</li>
          <li>Accès élargi aux marchés publics (clauses de faveur)</li>
          <li>Accès au PTCE et DLA</li>
          <li>Élément de reconnaissance et d’affichage</li>
          <li>Liste d’ « entreprise de l’économie sociale et solidaire » publiée par les CRESS</li>
        </ul>

        <h3>Comment l'obtenir ?</h3>
        <p>
          Les entreprises poursuivant appliquant les conditions suivantes dans leurs statuts sont considérées de “droit”
          comme appartenant à l’ESS :
        </p>
        <ul>
          <li>
            Assurer une gouvernance participative des associés, salariés et parties prenantes, dont l’expression n’est
            pas seulement liée à l’apport en capital ou contribution financière;
          </li>
          <li>
            Limiter la lucrativité: bénéfices majoritairement alloués au développement ou maintien de l’entreprise;
          </li>
          <li>Un caractère impartageable et non distribuable des réserves obligatoires constituées;</li>
          <li>
            Poursuivre un autre but que le seul partage des bénéfices (les associations, coopératives et fondations
            sont présumés satisfaire ces conditions automatiquement de par leur statut).
          </li>
        </ul>

        <h3>Inconvénients ?</h3>
        <p>
          Frein potentiel à l’investissement de fonds classiques (manque de confiance sur la lucrativité limitée et la
          limitation des écarts de salaire).
        </p>

        <hr className="minisep" />
        <div className="notes">
          <h4>Textes de référence :</h4>
          <ul>
            <li>
              Loi 2014-856 du 31 Juillet 2014 relative à l’économie sociale et solidaire (articles 1 et 2).
            </li>
            <li>
              Décret n°2015-858 du 31 Juillet 2015 relatif aux statuts des sociétés commerciales ayant la qualité d’entreprises de l’ESS
            </li>
          </ul>
        </div>
      </article>

      <a className="g-btn" href="https://impactfrance.eco/thematiques/tech-for-good/">Le comparatif détaillé by Mouvement Impact France</a>

    </div>
  );
}
