import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { StickyContainer } from 'react-sticky';

import useGoogleAnalytics from './useGoogleAnalytics';
import {Sidebar} from 'components/Sidebar';
import {Solution} from 'features/solution/Solution';
import {Content} from 'features/content/Content';
import {Navbar} from "./components/Navbar";
import {Header} from "./components/Header";
import {TopLink} from "./components/TopLink";
import {Loader} from "./components/Loader";

class App extends Component {
  constructor() {
    super();
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.state = {
      solutions: null,
      filters: {
        time: null,
        cost: null,
        impact: null,
      }
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_SOLUTION_JSON_URL)
      .then((response) => response.json())
      .then((data) => this.setState({solutions: addSolutionNumber(data)}));
  }

  handleUpdateFilter(filter, value) {
    this.setState((state) => {
      return {
        filters: {
          ...state.filters,
          [filter]: value
        }
      }
    });
  }

  render() {
    const {solutions, filters} = this.state;
    const forceLoader = false;
    if (forceLoader || null === solutions) {
      return (<Loader />);
    }

    const filteredSolutions = filterSolution(solutions, filters);

    return (<Router>
      <StickyContainer>
        <div id={"app"}>
          <Navbar />

          <Sidebar solutions={filteredSolutions}/>
          <div className="container-fluid" id="page-wrap">
            <Header />
            <Routes solutions={filteredSolutions}
                    onUpdateFilter={this.handleUpdateFilter}
            />
            <TopLink />
          </div>
        </div>
      </StickyContainer>
    </Router>);
  }
}

function Routes(props) {
  useGoogleAnalytics();
  return (
    <Switch>
      <Route path="/impact">
        <Content/>
      </Route>
      <Route path="/">
        <Solution {...props} />
      </Route>
    </Switch>
  );
}

function addSolutionNumber(solutions) {
  // Pour chaque solution, on va ajouter un `number`.
  // Il y a des solutions parents et des solutions enfants, la numérotation n'est pas la même pour les deux.
  // J'utilise la destructuration pour éviter les modifications d'objet par réference.

  let currentParentNumber = 1;
  return solutions.map((section) => {
    return {...section, items: section.items.map((item) => {
      let currentItemNumber = 1;
        return {...item, ...{
            number: currentParentNumber++,
            solutions: item.solutions.map((solution) => {
              return {...solution, ... {
                number: currentItemNumber++,
              }}
            })
          }}
      })
    };
  });
}

function filterSolution(solutions, filters) {
  return solutions.map((section) => {
    return {...section, items: section.items.filter((item) => {
        if(filters.time && item.difficulte > filters.time) {
          return false;
        }

        if(filters.cost && item.cout > filters.cost) {
          return false;
        }

        if(filters.impact && item.impact < filters.impact) {
          return false;
        }

        return true;
      })};
  });
}

export default App;
