import {React, Fragment, useState} from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useMediaQuery } from 'react-responsive'
import cx from 'classnames'
import 'react-rater/lib/react-rater.css'
import Cursor from "../../components/Cursor";
import {SolutionFilters} from "./SolutionFilters";

export function Solution(props) {
  const {solutions, onUpdateFilter} = props;

  const [solutionPanel, setSolutionPanel] = useState({item: null});

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  return (
    <div className="container solutions">
      <div className="row justify-content-lg-right justify-content-xl-center justify-content-md-end justify-content-sm-center">
        <div className="col-lg-7 col-md-9 col-sm-12">
          <SolutionFilters onUpdateFilter={onUpdateFilter} />

          {solutions.map((section) => (
            <section key={section.slugThem} id={section.slugThem}>
              <h2 className="section-title">{section.title}</h2>
              <div className="section-number">
                <div className="inner">
                  <div className="number">{section.chiffre_enjeu}</div>
                  <div className="text" dangerouslySetInnerHTML={{__html: section.chiffre_enjeu_descr}} />
                </div>
              </div>
              {section.items.map((item) => (<SolutionItem item={item} setSolutionPanel={setSolutionPanel} />))}

              <hr className="sep"/>

            </section>
          ))}
          <SolutionPanel
            item={solutionPanel.item}
            isDesktopOrLaptop={isDesktopOrLaptop}
            onRequestClose={() => {
              setSolutionPanel({item: null});
            }}/>
        </div>
      </div>
    </div>
  );
}

function generateItemMoreElement(item) {
  if (item.prestataire.length === 0 && false === item.usecase) {
    return null;
  }

  return (<div className="more">
    {item.prestataire.length > 0 &&
    <span>{item.prestataire.length} prestataire{item.prestataire.length > 1 && "s"}</span>}
    {item.usecase && <span>1 témoignage</span>}
  </div>);
}

function SolutionItem({item, setSolutionPanel, parentNumber}) {
  const moreElement = generateItemMoreElement(item);
  const handleClick = () => {
    if(null === moreElement) {
      return;
    }

    setSolutionPanel({
      item: item
    });
  };

  const parent = (<article key={item.slug} className={cx('solution', {'enabled': !!moreElement})} onClick={handleClick}>
    <h3 className="solution-title">
      <strong>{ Number.isInteger(parentNumber) && `${parentNumber} . ` }{item.number}</strong> {item.title}
    </h3>
    <div className="solution-info">
      <div className="cursors">
        <Cursor className="cursor-time" rating={item.difficulte} iconClassName="far fa-fw fa-clock"/>
        <Cursor className="cursor-cost" rating={item.cout} iconClassName="far fa-fw fa-euro-sign"/>
        <Cursor className="cursor-impact" rating={item.impact} iconClassName="far fa-fw fa-star"/>
      </div>
      {moreElement}
    </div>

  </article>);

  const children = item.solutions ? item.solutions.map((childItem) => (<SolutionItem parentNumber={item.number} item={childItem} setSolutionPanel={setSolutionPanel} />)) : [];

  return [parent, ...children];
}

function SolutionPanel({isDesktopOrLaptop, onRequestClose, item}) {
  if (null === item) {
    return null;
  }

  return <SlidingPane
    className=""
    width={isDesktopOrLaptop ? '50%': '90%'}
    overlayClassName="some-custom-overlay-class"
    isOpen={!!item}
    title=""
    onRequestClose={onRequestClose}
  >
    <div>
      <h1 className="title">{item.title}</h1>
      <div className="cursors">
        <Cursor className="cursor-time" rating={item.difficulte} iconClassName="far fa-fw fa-clock" labels={["Rapide", "Moyenne", "Longue"]}/>
        <Cursor className="cursor-cost" rating={item.cout} iconClassName="far fa-fw fa-euro-sign"
                labels={["Peu coûteux", "Moyennement coûteux", "Très coûteux"]}/>
        <Cursor className="cursor-impact" rating={item.impact} iconClassName="far fa-fw fa-star"
                labels={["Impact faible", "Impact moyen", "Impact fort"]}/>
      </div>
      {item.prestataire.length > 0 &&
      <section>
        <h2 className="subtitle">prestataires, sources :</h2>
        <ul className="providers">
          {item.prestataire.map((prestataire) => (
            <Fragment key={prestataire.name}>
              {prestataire.link && <li key={prestataire.name}><a href={prestataire.link}>{prestataire.name}</a></li>}
              {!prestataire.link && <li key={prestataire.name}>{prestataire.name}</li>}
            </Fragment>
          ))}
        </ul>
      </section>
      }
      {item.usecase &&
      <>
        <hr className="minisep"/>
        <section>
          <h2 className="subtitle">témoignage</h2>


          <div className="bloc-author">
            {item.usecase_content.pic_entrepreneur &&
            <img src={`${process.env.REACT_APP_SOLUTION_PICTURE_BASE_URL}${item.usecase_content.pic_entrepreneur}`} alt={item.usecase_content.entrepreneur} />
            // <img src={`http://localhost:3000/team/${item.usecase_content.pic_entrepreneur}`} alt={item.usecase_content.entrepreneur} />
            }
            <div className="author">
              <div className="quote">
                " {item.usecase_content.conseil} "
              </div>
              <div className="name">{item.usecase_content.entrepreneur}</div>
              {/*<div className="job-title">{usecase_content.entreprise}</div>*/}
            </div>
          </div>

          <div className="bloc-company">
            <h3 className="company">
              {item.usecase_content.entreprise}
              <small>{item.usecase_content.coeur_de_business}</small>
            </h3>

            <div className="numbers">
              <div className="number">
                <div className="libelle">{item.usecase_content.date_de_creation}</div>
                <div className="desc">création de l'entreprise</div>
              </div>
              <div className="number">
                <div className="libelle">{item.usecase_content.date_du_projet}</div>
                <div className="desc">mise en place du projet</div>
              </div>
              <div className="number">
                <div className="libelle">{item.usecase_content.nb_employes} <small>employés</small></div>
                <div className="desc">effectif au moment de la mise en place</div>
              </div>
            </div>
          </div>


          <div className="testimony-content">
            {item.usecase_content.motivation &&
              <>
                <h3>Motivation :</h3>
                <p>{item.usecase_content.motivation}</p>
              </>
            }

            {item.usecase_content.solution &&
            <>
              <h3>Solution :</h3>
              <p>{item.usecase_content.solution}</p>
            </>
            }

            {item.usecase_content.organisation &&
            <>
              <h3>Ressources nécessaires (H/€) / organisation :</h3>
              <p>{item.usecase_content.organisation}</p>
            </>
            }

            {item.usecase_content.effets.length > 0 &&
            <>
              <h3>Effets sur l’entreprise :</h3>
              {item.usecase_content.effets.map((effet, idx) => (<p key={idx}>{effet}</p>))}
            </>
            }

            {item.usecase_content.conseil &&
            <>
              <h3>Un conseil :</h3>
              <p>{item.usecase_content.conseil}</p>
            </>
            }
          </div>
        </section>
      </>
      }

    </div>
  </SlidingPane>;
}
