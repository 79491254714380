import {slide as Menu} from "react-burger-menu";
import {NavLink} from "react-router-dom";
import React from "react";

export function BurgerMenu(props) {
  return (
    <Menu {...props}>
      <NavLink to="/" >Les solutions</NavLink>
      <NavLink to="/impact/standard" >L’impact est-il la nouvelle norme ?</NavLink>
      <NavLink to="/impact/investor" >Le point de vue des investisseurs</NavLink>
      <NavLink to="/impact/labels" >Le comparatif des labels</NavLink>
    </Menu>
  );
}
