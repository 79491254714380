import React from "react";
import cx from "classnames";

export default class Cursor extends React.Component {
  render() {
    const {rating, labels, iconClassName, className} = this.props;

    return (
      <div className={cx('cursor', className)}>
        <span className={cx('icon', {'active': rating >= 1})}>
          <i className={iconClassName}/>
        </span>
        <span className={cx('icon', {'active': rating >= 2})}>
          <i className={iconClassName}/>
        </span>
        <span className={cx('icon', {'active': rating >= 3})}>
          <i className={iconClassName}/>
        </span>
        {labels && <div className="cursor-label">{labels[rating - 1]}</div>}
      </div>
    );
  }
}
